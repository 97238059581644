import React from "react";
import Loadable from "@loadable/component";
import { BackgroundGradient } from "shared/types";
import columnLine from "assets/svgs/skills-line.svg";
import styles from "./SkillsTree.module.scss";
import { SkillsLine } from "./types";
import SkillsRowDesktop from "./SkillsRowDesktop";
import useViewport from "shared/hooks/useViewport";
import { generateShortUUID } from "utils";

export const skills: SkillsLine[] = [
  {
    background: BackgroundGradient.Gradient1,
    skills: ["content writing", "email marketing", "marketing", "seo", "social media marketing"],
    primarySkillIndex: 2,
  },
  {
    background: BackgroundGradient.Gradient2,
    skills: ["prototyping", "mobile ui", "desktop ui", "product", "ux/ui design", "figma", "sketch"],
    primarySkillIndex: 3,
  },
  {
    background: BackgroundGradient.Gradient5,
    skills: ["manual testing", "automated testing", "qa testing", "selenium", "jasmine", "jest"],
    primarySkillIndex: 2,
  },
  {
    background: BackgroundGradient.Gradient3,
    skills: ["react native", "react", "angular 2+", "front-end", "tailwind", "bootstrap", "material design", "gsap"],
    primarySkillIndex: 3,
  },
  {
    background: BackgroundGradient.Gradient4,
    skills: ["Django", "GraphQL", "node.js", "nestjs", "back-end", "postgres", "mongodb", "dynamodb", "redis"],
    primarySkillIndex: 4,
  },
  {
    background: BackgroundGradient.Gradient6,
    skills: [
      "aws",
      "azure",
      "terraform",
      "digital ocean",
      "devops",
      "ci/cd pipelines",
      "docker",
      "kubernetes",
      "serverless",
    ],
    primarySkillIndex: 4,
  },
  {
    background: BackgroundGradient.Gradient9,
    skills: [
      "Smart Contracts",
      "NFTs",
      "dApps",
      "blockchain",
      "Ethereum",
      "Hardhat",
      "Truffle",
      "Solidity",
    ],
    primarySkillIndex: 3,
  },
  {
    background: BackgroundGradient.Gradient10,
    skills: [
      "machine learning",
      "gan",
      "nlp",
      "ARTIFICIAL INTELLIGENCE",
      "computer vision",
      "TensorFlow",
      "Keras",
      "PyTorch",
    ],
    primarySkillIndex: 3,
  },
  {
    background: BackgroundGradient.Gradient7,
    skills: [
      "algorithms",
      "data structures",
      "internet of things",
      "extra",
      "reverse engineering",
      "python",
      "java",
      "c++",
    ],
    primarySkillIndex: 3,
  },
];

const SkillsRowMobile = Loadable(() => import("./SkillsRowMobile"));
const SkillsTree: React.FC = ({ className }: HTMLDivElement) => {
  const { width } = useViewport();
  const displaysMobileComponent = width <= 1300;
  const SkillsRowComponentClass = displaysMobileComponent ? SkillsRowMobile : SkillsRowDesktop;
  return (
    <div className={[styles.skillsTreeContainer, className].join(" ")}>
      {!displaysMobileComponent && <img src={columnLine} className={styles.skillsTreeVerticalLine} />}
      {skills.map((skillData) => (
        <SkillsRowComponentClass key={generateShortUUID()} skillsData={skillData} />
      ))}
    </div>
  );
};

export default SkillsTree;
