import React, { FunctionComponent } from "react";
import styles from "./Chip.module.scss";
import { BackgroundGradient } from "utils/constants";

export enum ChipVariant {
  small,
  big,
}

type Props = {
  label: string;
  background: BackgroundGradient;
  variant?: ChipVariant;
} & React.HTMLAttributes<HTMLElement>;

const Chip: FunctionComponent<Props> = ({
  label = "",
  variant = ChipVariant.small,
  background,
  className,
  ...props
}) => {
  const backgroundStyle = styles[`chip${background}`];
  const variantStyle = variant === ChipVariant.small ? styles.chipSmall : styles.chipBig;

  return (
    <div className={[styles.chip, backgroundStyle, variantStyle, className].join(" ")} {...props}>
      {label}
    </div>
  );
};

export default Chip;
