import { ReactNode } from "react";
import { BackgroundGradient, QuestionType, AnswerType } from "./enums";

export type QuestionCard = {
  icon: ReactNode;
  text: any;
  activeBackground: BackgroundGradient;
};

export type Question = {
  card: QuestionCard;
  questions: { question: string; children: ReactNode }[];
};

export type Skill = {
  name: string;
  background: BackgroundGradient;
};

export type TeamMember = {
  id: string;
  name: string;
  position: string;
  experience: string;
  resume: string;
  photo: string;
  skills: Skill[];
  entryAnimation: { [key: string]: any };
};

export type InterviewQues = {
  question: {
    type: QuestionType;
    language: string;
    title: string;
    editorCode: string;
  };
  answer: {
    type: AnswerType;
    language: string;
    answers: string[];
  };
};

export { BackgroundGradient, Direction, SeparatorBackground } from "./enums";
