import React, { useEffect } from "react";

const GoogleAnalytics: React.FC = () => {
  useEffect(() => {
    if (process.env.GATSBY_GOOGLE_ANALYTICS_ENABLED === "false") {
      return;
    }
    const scriptTagSrc = document.createElement("script");
    scriptTagSrc.async = true;
    scriptTagSrc.src = "https://www.googletagmanager.com/gtag/js?id=G-4LK69VJR5B"

    const scriptTagCode = document.createElement("script");
    scriptTagCode.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-4LK69VJR5B');`;

    document.getElementsByTagName("head")[0].appendChild(scriptTagSrc);
    document.getElementsByTagName("head")[0].appendChild(scriptTagCode);
  }, []);

  return <></>;
};

export default GoogleAnalytics;
