import React, { useEffect, useRef, useState } from "react";
import Chip from "components/Chip";
import svgLine from "assets/svgs/skillsLine-line.svg";
import styles from "./SkillsRow.module.scss";
import { SkillsRowProps } from "./types";

const SkillsRowDesktop: React.FC<SkillsRowProps> = ({ skillsData }, { ...props }) => {
  const chipsContainerRef = useRef(null);
  const [chipsContainerStyle, setChipsContainerStyle] = useState({});

  const computeChipsContainerStyle = () => {
    if (!chipsContainerRef) {
      return;
    }
    const htmlNode = chipsContainerRef.current;
    const parentBoundingRect = htmlNode.getBoundingClientRect();
    const childBoundingRect = htmlNode.childNodes[skillsData.primarySkillIndex].getBoundingClientRect();
    return {
      marginLeft:
        2 * (parentBoundingRect.x + parentBoundingRect.width / 2 - (childBoundingRect.x + childBoundingRect.width / 2)),
    };
  };

  useEffect(() => {
    setTimeout(() => {
      setChipsContainerStyle(computeChipsContainerStyle());
    }, 100);
  }, []);

  return (
    <div className={[styles.skillsRow, styles.skillsRowDesktop].join(" ")} {...props}>
      <img src={svgLine} className={styles.backgroundLine} />
      <div className={styles.chipsContainer} ref={chipsContainerRef} style={chipsContainerStyle}>
        {skillsData.skills.map((skill, index) => (
          <Chip key={index} className={styles.skillChip} background={skillsData.background} label={skill} />
        ))}
      </div>
    </div>
  );
};

export default SkillsRowDesktop;
